const baseURL = process.env.REACT_APP_BASE_URL;
const contentTypePair = { "Content-Type": "application/json" }

export const getConfig = async (API_KEY) => {
  let result = await fetch(`${baseURL}/organizations/${API_KEY}`, {
    method: 'GET',
    headers: {
      ...contentTypePair,
      "x-api-key": "123"
    }
  }).then(response => response.json()).then(data => data);

  return result;
}

export const fetchCustomer = async (data, API_KEY, lng) => {
  let result = await fetch(`${baseURL}/donors/${API_KEY}`, {
    method: 'POST',
    headers: {
      ...contentTypePair,
      "x-api-key": "123",
      language: lng,
    },
    body: JSON.stringify(data)
  }).then(response => response.json()).then(returnedData => returnedData);

  return result;
}

export const fetchPayment = async (data, API_KEY, lng) => {
  let result = await fetch(`${baseURL}/payments/pay/${API_KEY}`, {
    method: 'POST',
    headers: {
      ...contentTypePair,
      "x-api-key": "123",
      language: lng,
    },
    body: JSON.stringify(data)
  }).then(response => response.json()).then(returnedData => returnedData);
  
  return result;
}