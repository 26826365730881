import { Row, Col } from "react-bootstrap";

// GTM 
import TagManager from "react-gtm-module";

// Components
import Checkout from "./components/CheckoutContainer";
import DropdownLocale from "./components/DropdownLocale";

// Translations 
import { startI18N } from "./i18n";
import checkoutTranslations from "./i18n/translations";

// Styles
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";

const App = () => {
  startI18N(checkoutTranslations)
  
  const URL = new URLSearchParams('?'+window.location.href.split('?')[1]);
  const CHECKOUT_ID = URL.get("ID");
  const GTM_ID = URL.get("GTM");

  useEffect(() => TagManager.initialize({gtmId: GTM_ID}), [])

  return (
    <div className="position-relative w-100" id="section-checkout">
      <Col className="position-absolute wrapper-button-i18n">
        <DropdownLocale />
      </Col>
      <Row className="justify-content-center g-0 row-checkout">
        <Checkout checkoutID={CHECKOUT_ID} />
      </Row>
    </div>
  );
};

export default App;