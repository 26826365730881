// Libs
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const startI18N = async (translations, callback = () => {}) => {
  const i18nConfig = {
    resources: translations,
    fallbackLng: "pt-BR",
    defaultNS: "translations",
  };

  i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);
};

export const isEs = (selectedLanguage) => {
  return `${selectedLanguage}`.substring(0, 2) === "es";
};

export const isEn = (selectedLanguage) => {
  return `${selectedLanguage}`.substring(0, 2) === "en";
};

export const isPtBr = (selectedLanguage) => {
  return !isEn(selectedLanguage) && !isEs(selectedLanguage);
};